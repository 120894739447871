import {
  QFormControl,
  QSelect,
  QSelectItem,
  useCurrentUser,
} from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

const disabledTrainingFilterOption = {
  label: 'All documents',
  value: null,
};
const enabledTrainingFilterOption = {
  label: 'Training documents',
  value: 'for_training',
};
const trainingFilterOptions: QSelectItem<string, string | null>[] = [
  disabledTrainingFilterOption,
  enabledTrainingFilterOption,
];

export const LibraryTrainingFilterControl = () => {
  const currentUser = useCurrentUser();

  if (currentUser.permissions.can_view_workspace) {
    return null;
  }

  return (
    <Controller
      name="filter_by"
      render={({ field: { onChange, value }, fieldState }) => (
        <QFormControl
          label="Documents"
          isInvalid={!!fieldState.error}
          error={fieldState.error?.message}
        >
          <QSelect
            size="sm"
            options={trainingFilterOptions}
            onChange={(option) => onChange(option?.value)}
            value={value}
            aria-label="Document filter"
            data-cy={'workspace-status-filter'}
          />
        </QFormControl>
      )}
    />
  );
};
