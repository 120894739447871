import {
  QFormControl,
  QMultiSelect,
  QSelectPlaceholder,
  QText,
} from '@qualio/ui-components';
import { Controller } from 'react-hook-form';
import { Tag } from '../../../../api/model/tag';
import { TagLabel } from '../../TagLabel/TagLabel';

type TagsFilterControlProps = {
  tags: Tag[];
};

export const TagsFilterControl = ({ tags }: TagsFilterControlProps) => {
  return (
    <Controller
      name="filteringTag"
      render={({ field: { onChange, value }, fieldState }) => (
        <QFormControl
          label="Tags"
          isInvalid={!!fieldState.error}
          error={fieldState.error?.message}
        >
          <QMultiSelect
            size="sm"
            options={tags.map((tag) => ({
              label: <TagLabel tag={tag} />,
              value: String(tag.id),
            }))}
            onChange={(value) => {
              onChange(value.map((v) => v.value));
            }}
            value={value}
            data-cy={'library-tags'}
            filterOption={({ data: option }, searchTerm) => {
              if (!searchTerm) {
                return true;
              }

              return option.label.props.tag.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }}
          >
            <QSelectPlaceholder>
              {/* # Keeping incorrect data-cy here to not break tests */}
              <QText fontSize="sm" data-cy="library-tag-filter">
                Filter by tags...
              </QText>
            </QSelectPlaceholder>
          </QMultiSelect>
        </QFormControl>
      )}
    />
  );
};
