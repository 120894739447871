import { QFormControl, QSelect } from '@qualio/ui-components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { StatusFilterMapType } from '../types';

type StatusFilterControlProps = {
  statusFiltersMap: StatusFilterMapType;
};

export const StatusFilterControl = ({
  statusFiltersMap,
}: StatusFilterControlProps) => {
  const getStatusOptions = (statusFiltersMap: StatusFilterMapType) =>
    Object.keys(statusFiltersMap).map((key) => ({
      label: statusFiltersMap[key].label,
      value: statusFiltersMap[key].isDefault ? null : key, // Set the config's default option as the default empty value
    }));

  const statusOptions = useMemo(
    () => getStatusOptions(statusFiltersMap),
    [statusFiltersMap],
  );

  return (
    <Controller
      name="subfilter"
      render={({ field: { onChange, value }, fieldState }) => (
        <QFormControl
          label="Status"
          isInvalid={!!fieldState.error}
          error={fieldState.error?.message}
        >
          <QSelect
            size="sm"
            options={statusOptions}
            onChange={(option) => onChange(option?.value)}
            value={value}
            aria-label="Document status"
            data-cy={'workspace-status-filter'}
          />
        </QFormControl>
      )}
    />
  );
};
