import {
  QFormControl,
  QMultiSelect,
  QSelectPlaceholder,
  QText,
} from '@qualio/ui-components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Template } from '../../../../api/templateApi';
import { getTemplatesOptions } from '../utils';

type TemplatesFilterControlProps = {
  templates: Template[];
};

export const TemplatesFilterControl = ({
  templates,
}: TemplatesFilterControlProps) => {
  const templatesOptions = useMemo(
    () => getTemplatesOptions(templates, 'prefix'),
    [templates],
  );

  return (
    <Controller
      name="filteringTemplates"
      render={({ field: { onChange, value }, fieldState }) => (
        <QFormControl
          label="Type"
          isInvalid={!!fieldState.error}
          error={fieldState.error?.message}
        >
          <QMultiSelect
            size="sm"
            options={templatesOptions}
            onChange={(value) => {
              onChange(value.map((v) => v.value));
            }}
            value={value}
            data-cy={'templates-filter'}
            filterOption={({ data: option }, searchTerm) => {
              if (!searchTerm) {
                return true;
              }

              return option.label
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }}
          >
            <QSelectPlaceholder>
              <QText fontSize="sm" data-cy="workspace-templates-filter">
                Select...
              </QText>
            </QSelectPlaceholder>
          </QMultiSelect>
        </QFormControl>
      )}
    />
  );
};
